import React from 'react';
import ShortArrayLeft from '../../images/short-arrow-left.svg';
import renderHtml from 'htmr';
import { decode } from 'html-entities';
import { getFormatNumberWithCommas } from '../../modules/timeline/components/EnterButton/utils';
import { useFetch } from '../../hooks';
import { Link } from 'gatsby';

const LanguageDonationDetailCard = ({ languageDonationCard, id }) => {
  const countryImageUrl = `https://flagcdn.com/h24/${languageDonationCard.flagCode.text}.png`;

  const [getLanguageProjectByIdRaisedMoney, raisedMoneyDataForLanguageById, error, loading] = useFetch();

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (id) {
        getLanguageProjectByIdRaisedMoney(`api/donate/total-donations-by-child-project-id/${id}`, {
          onSuccess: () => {},
          onError: () => {},
        });
      }
    }
  }, [id]);

  const getPercentageOfRaisedMoney = (goalAmount) => {
    if (raisedMoneyDataForLanguageById?.data?.totalAmount) {
      const percentage = (raisedMoneyDataForLanguageById?.data?.totalAmount / goalAmount) * 100;
      return Math.ceil(percentage) + '%';
    }
    return 0;
  };

  return (
    <div className="language-detail-page give ">
      <div className="pd-x-lg ">
        <div className="pd-x-lg ">
          <Link to="/language-donations-page" className="pd-x-lg back-button-container">
            <img src={ShortArrayLeft} />
            <p>Back</p>
          </Link>
        </div>
      </div>
      <div className="section title-section" style={{ paddingTop: '32px' }}>
        <div className="pd-x-lg">
          <div className="">
            <div className="   d-flex" style={{ gap: 10 }}>
              <img className="thumbnail-image " src={countryImageUrl} alt={countryImageUrl} />
              <div>
                <p>Give for Lineage in </p>
                <p className="title">{languageDonationCard?.languageName?.text}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="language-detail-donation-card bg-white">
          <div className="pd-x-lg">
            <div className="">
              <div className="progress-track">
                <div
                  className="progress-thumb"
                  style={{ width: getPercentageOfRaisedMoney(+languageDonationCard?.goal) }}
                ></div>
              </div>
              <div className="goal-container">
                <p>
                  $
                  <b>
                    {raisedMoneyDataForLanguageById?.data?.totalAmount
                      ? getFormatNumberWithCommas(raisedMoneyDataForLanguageById?.data?.totalAmount)
                      : 0}
                  </b>{' '}
                  Raised
                </p>
                <p>
                  $<b>{getFormatNumberWithCommas(+languageDonationCard?.goal)} </b>
                  Goal
                </p>
              </div>
              <p className="descriptions">{renderHtml(decode(languageDonationCard?.upperDescriptions?.html))}</p>
            </div>
            <div className="btn-group ">
              <Link to={`#donate-box`} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
                Give Now
              </Link>
            </div>
          </div>
          <div>
            <img
              className="featured-image pd-x-lg"
              src={languageDonationCard?.featureImage?.fluid?.src}
              alt={languageDonationCard}
            />
          </div>
        </div>
      </div>
      <div className="lower-descriptions-container  bg-gray">
        <div className="pd-x-lg">
          <p className="descriptions ">{renderHtml(decode(languageDonationCard?.lowerDescriptions?.html))}</p>
        </div>
      </div>
    </div>
  );
};

export default LanguageDonationDetailCard;
