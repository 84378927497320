import React, { useState } from 'react';
import { graphql } from 'gatsby';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import Donation from '../../components/Donation';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import { getListDonationProjectForSelect } from '../../utils/functions';
import LanguageDonationDetailCard from '../../components/Donation/LanguageDonationDetailCard';

const LanguageDonationDetailsPage = (props) => {
  const {
    data: { bgGive, patreonThumb, languageDonationPage, donationsDetails },
    pageContext: { id },
  } = props;

  const languageDonationDetailsData = languageDonationPage.edges[0].node.data;
  const languageProjectDetails = languageDonationDetailsData.body.find((item) => item.id === id);
  const donationDetailsData = donationsDetails.edges[0].node.data;

  const customProjectsLists = getListDonationProjectForSelect(
    donationDetailsData.projects,
    languageDonationDetailsData.body
  );

  return (
    <BodyClassName className="body-light page-give">
      <DefaultLayoutComponent title="Donate">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={languageDonationDetailsData.backgroundImage.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
          </BackgroundImage>
          <section className="give-container over_lpd-container bg-dark pd-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className=" bg-light  pd-b-0">
                <LanguageDonationDetailCard id={id} languageDonationCard={languageProjectDetails.primary} />
                <div id="donate-box">
                  <Donation
                    isCustomDonation={true}
                    patreonThumb={patreonThumb}
                    showTab={false}
                    projectsLists={customProjectsLists}
                    defaultSelected={customProjectsLists.find((item) => item.value === id)}
                    hideTaxDeductibleTab={true}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgGive: file(relativePath: { eq: "bg-give.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    patreonThumb: file(relativePath: { eq: "patreon-thumb.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
    languageDonationPage: allPrismicLanguageDonationsPage {
      edges {
        node {
          data {
            title {
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            titleImage: title_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            body {
              __typename
              ... on PrismicLanguageDonationsPageBodyLanguageProjects {
                slice_type
                slice_label
                id
                primary {
                  languageName: language_name {
                    text
                  }
                  shortDescriptions: short_descriptions {
                    text
                  }
                  flagCode: flag_code {
                    text
                  }
                  upperDescriptions: upper_descriptions {
                    text
                    html
                  }
                  lowerDescriptions: lower_descriptions {
                    text
                    html
                  }
                  featureImage: featured_image {
                    alt
                    url
                    fluid(maxWidth: 600) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  goal
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LanguageDonationDetailsPage;
